import React from "react";
import { useNavigate } from "react-router-dom";
import { AiOutlineHome } from "react-icons/ai";

import "./ButtonHome.css";

export default function ButtonHome() {
  const user = JSON.parse(localStorage.getItem("user"));
  const navigate = useNavigate();
  const home = () => {
    navigate("/home");
  };
  return (
    <div className="header-container">
      <h1 className="text-header-empleado">{user?.empleado?.nombreEmpleado}</h1>
      <button className="btn-home" title="Regresar" onClick={() => home()}>
        <AiOutlineHome className="icon-btn-logout" color="#fff" />
      </button>
    </div>
  );
}
