import React, { useState, useEffect } from "react";
import "./Modal.css";
import { RiCloseLine } from "react-icons/ri";
import Textfield from "../Textfield/Textfield";
import axios from "../../utils/axios";
import { errorHandler, openSuccessToast } from "../../utils/errorToast";

export default function ModalRutas({ setIsOpen, reloadRutas, ruta }) {
  const tiposRecorridos = ["SENCILLO", "REDONDO"];
  const [values, setValues] = useState({
    rutaId: ruta ? ruta.rutaId : "",
    nombreRuta: ruta ? ruta.nombreRuta : "",
    ciudadOrigen: ruta ? ruta.ciudadOrigen : "",
    ciudadDestino: ruta ? ruta.ciudadDestino : "",
    tipoRuta: ruta ? ruta.tipoRuta : "",
    precio: ruta
      ? new Intl.NumberFormat("en-US", { minimumFractionDigits: 0 }).format(
          ruta.precio
        )
      : "",
    zonas: ruta ? ruta.zonas : "",
    eps: ruta ? ruta.eps : "",
  });
  const [ciudades, setCiudades] = useState([]);
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === "precio") {
      var val = value.replace(/(\..*)\./g, "$1");
      var x = Number(val.replace(/,/g, ""));
      var formatter = new Intl.NumberFormat("en-US", {
        minimumFractionDigits: 0,
      });

      setValues({
        ...values,
        [name]: formatter.format(x),
      });
    } else {
      setValues({
        ...values,
        [name]: value,
      });
    }
  };

  useEffect(() => {
    axios
      .get("getAllCiudades")
      .then((res) => {
        if (res.status >= 200 && res.status < 300) {
          setCiudades(res.data);
        }
      })
      .catch((error) => {
        errorHandler(error, "Error al cargar las ciudades");
      });
  }, [setCiudades]);
  const addRuta = () => {
    const data = {
      ...values,
      ["precio"]: values.precio.replace(/,/g, ""),
    };
    axios
      .post("createRuta", data)
      .then((res) => {
        if (res.status >= 200 && res.status < 300) {
          setIsOpen(false);
          openSuccessToast("Se ha guardado la ruta");
          reloadRutas();
        }
      })
      .catch((error) => {
        errorHandler(error, "Error al crear la ruta");
      });
  };
  return (
    <>
      <div className="modal-dark-background" onClick={() => setIsOpen(false)} />
      <div className="modal-container">
        <div className="modal-rutas">
          <div className="modal-header">
            <h1 className="heading">{ruta ? "Editar ruta" : "Crear ruta"}</h1>
          </div>
          <button className="closeBtn" onClick={() => setIsOpen(false)}>
            <RiCloseLine style={{ marginBottom: "-3px" }} />
          </button>
          <div className="modalContent">
            <Textfield
              name="nombreRuta"
              onChange={handleInputChange}
              value={values.nombreRuta}
              text="Nombre ruta"
              type="text"
            />
            <div className="form-group-rutas">
              <select
                className="form-select-rutas"
                placeholder=" "
                onChange={(e) => {
                  setValues({
                    ...values,
                    ["ciudadOrigen"]: ciudades[e.target.options.selectedIndex],
                  });
                }}
                value={values?.ciudadOrigen?.nombreCiudad}
              >
                {ciudades.map((element) => (
                  <option key={element.id} value={element.nombreCiudad}>
                    {element.nombreCiudad}
                  </option>
                ))}
                <option value="none" selected disabled hidden>
                  Seleccione la ciudad origen
                </option>
              </select>
              <label htmlFor="origen" className="form-label-rutas">
                Origen
              </label>
            </div>
            <div className="form-group-rutas">
              <select
                className="form-select-rutas"
                placeholder=" "
                onChange={(e) => {
                  setValues({
                    ...values,
                    ["ciudadDestino"]: ciudades[e.target.options.selectedIndex],
                  });
                }}
                value={values?.ciudadDestino?.nombreCiudad}
              >
                {ciudades.map((element) => (
                  <option key={element.id} value={element.nombreCiudad}>
                    {element.nombreCiudad}
                  </option>
                ))}
                <option value="none" selected disabled hidden>
                  Seleccione la ciudad destino
                </option>
              </select>
              <label htmlFor="destino" className="form-label-rutas">
                Destino
              </label>
            </div>
            <div className="form-group-rutas">
              <select
                className="form-select-rutas"
                placeholder=" "
                onChange={(e) => {
                  setValues({
                    ...values,
                    ["tipoRuta"]: e.target.value,
                  });
                }}
                value={values?.tipoRuta}
              >
                <option value="" selected disabled hidden>
                  Seleccione el tipo de recorrido
                </option>
                {tiposRecorridos.map((element) => (
                  <option key={element} value={element}>
                    {element}
                  </option>
                ))}
              </select>
              <label htmlFor="tipoRuta" className="form-label-rutas">
                Tipo de recorrido
              </label>
            </div>
            <Textfield
              name="precio"
              onChange={handleInputChange}
              value={values.precio}
              text="Precio"
              type="text"
            />
            {/* <div className="form-group">
              <input
                type="text"
                className="form-input"
                name="currency"
                placeholder=" "
                onChange={handleInputChange}
                value={values.currency}
                id="currency"
                // pattern="^\$\d{1,3}(,\d{3})*(\.\d+)?$"
              />
              <label htmlFor="currency" className="form-label">
                Precio
              </label>
            </div>
            <input
              type="text"
              name="currency-field"
              id="currency-field"
              pattern="^\$\d{1,3}(,\d{3})*(\.\d+)?$"
              value=""
              data-type="currency"
              placeholder="$1,000,000.00"
            ></input> */}
            <Textfield
              name="zonas"
              onChange={handleInputChange}
              value={values.zonas}
              text="Zonas"
              type="text"
            />
            <Textfield
              name="eps"
              onChange={handleInputChange}
              value={values.eps}
              text="EPS"
              type="text"
            />
            <button type="submit" className="btn_submit" onClick={addRuta}>
              {ruta ? "Guardar ruta" : "Agregar ruta"}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
