import React, { useState } from "react";
import Card from "../../components/Card/Card";
import ModalChangePassword from "../../components/Modal/ModalChangePassword";
import "./HomePage.css";
import { useNavigate } from "react-router-dom";
import {
  FaFileInvoiceDollar,
  FaRoute,
  FaUserAlt,
  FaUsers,
  FaUserEdit,
} from "react-icons/fa";
import { VscTable } from "react-icons/vsc";
import { AiFillLock } from "react-icons/ai";
import { BsFillCarFrontFill, BsPersonFillAdd } from "react-icons/bs";
import { HiOutlineLogout } from "react-icons/hi";

export default function HomePage() {
  const user = JSON.parse(localStorage.getItem("user"));
  const isConductorInterno = user?.roles.includes("ROLE_CONDUCTOR_INTERNO");
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const redirectToPage = (path) => {
    localStorage.removeItem("observacionesConductor");
    navigate(path);
  };
  const logout = () => {
    localStorage.removeItem("user");
    redirectToPage("/login");
  };
  const crearCuentaCard = () => {
    return (
      <Card
        text="Crear cuenta cobro"
        icon={<FaFileInvoiceDollar className="icon-btn-cards" color="#fff" />}
        onClick={() => redirectToPage("/cuenta")}
      />
    );
  };
  const verCuentasCard = () => {
    return (
      <Card
        text="Ver cuentas de cobro"
        icon={<VscTable className="icon-btn-cards" color="#fff" />}
        onClick={() => redirectToPage("/vercuentas")}
      />
    );
  };
  const verConductoresCard = () => {
    return (
      <Card
        text="Ver titulares"
        icon={<BsFillCarFrontFill className="icon-btn-cards" color="#fff" />}
        onClick={() => redirectToPage("/conductores")}
      />
    );
  };
  const verRutasCard = () => {
    return (
      <Card
        text="Ver rutas"
        icon={<FaRoute className="icon-btn-cards" color="#fff" />}
        onClick={() => redirectToPage("/rutas")}
      />
    );
  };
  const verPacientesCard = () => {
    return (
      <Card
        text="Ver pacientes"
        icon={<FaUserAlt className="icon-btn-cards" color="#fff" />}
        onClick={() => redirectToPage("/pacientes")}
      />
    );
  };
  const cambiarContraseñaCard = () => {
    return (
      <div className="two-items-grid">
        <Card
          text="Cambiar contraseña"
          icon={<AiFillLock className="icon-btn-cards" color="#fff" />}
          onClick={() => setIsModalOpen(true)}
        />
      </div>
    );
  };
  const registroCard = () => {
    return (
      <Card
        text="Registrar usuario"
        icon={<BsPersonFillAdd className="icon-btn-cards" color="#fff" />}
        onClick={() => redirectToPage("/registro")}
      />
    );
  };
  const userListCard = () => {
    return (
      <Card
        text="Ver usuarios"
        icon={<FaUsers className="icon-btn-cards" color="#fff" />}
        onClick={() => redirectToPage("/verusuarios")}
      />
    );
  };
  const editProfileCard = () => {
    return (
      <Card
        text={isConductorInterno ? "Ver información" : "Editar información"}
        icon={<FaUserEdit className="icon-btn-cards" color="#fff" />}
        onClick={() => redirectToPage("/editarconductor")}
      />
    );
  };

  const returnViewAccordingToRole = () => {
    if (user?.roles.includes("ROLE_ADMIN")) {
      return (
        <div className="home-card-container-admin">
          {crearCuentaCard()}
          {verCuentasCard()}
          {verConductoresCard()}
          {verRutasCard()}
          {verPacientesCard()}
          {cambiarContraseñaCard()}
          {registroCard()}
          {userListCard()}
        </div>
      );
    } else if (
      user?.roles.includes("ROLE_CONDUCTOR") ||
      user?.roles.includes("ROLE_CONDUCTOR_INTERNO")
    ) {
      return (
        <div className="home-card-container-admin">
          {crearCuentaCard()}
          {verCuentasCard()}
          {editProfileCard()}
          {cambiarContraseñaCard()}
        </div>
      );
    } else {
      return (
        <div className="home-card-container-admin">
          {verCuentasCard()}
          {verRutasCard()}
          {verPacientesCard()}
          {cambiarContraseñaCard()}
        </div>
      );
    }
  };

  return (
    <section className="home-container">
      <div className="header-container">
        <h1 className="text-header-empleado">{user.empleado.nombreEmpleado}</h1>
        <button
          className="btn-logout"
          title="Cerrar sesión"
          onClick={() => logout()}
        >
          <HiOutlineLogout className="icon-btn-logout" color="#fff" />
        </button>
      </div>

      <section className="home-content-container">
        {returnViewAccordingToRole()}
      </section>
      {isModalOpen && <ModalChangePassword setIsOpen={setIsModalOpen} />}
    </section>
  );
}
