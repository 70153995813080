import React, { useState, useEffect } from "react";
import "./Modal.css";
import { RiCloseLine } from "react-icons/ri";
import Textfield from "../Textfield/Textfield";
import { FaEdit, FaTrashAlt } from "react-icons/fa";
import Table from "../Table/Table";
import axios from "../../utils/axios";
import { errorHandler, openSuccessToast } from "../../utils/errorToast";
import IconButtonTable from "../IconButtonTable/IconButtonTable";

export default function ModalCiudades({ setIsOpen }) {
  const user = JSON.parse(localStorage.getItem("user"));
  const isAdmin = user?.roles.includes("ROLE_ADMIN");
  const columns = [
    {
      Header: "Ciudad",
      accessor: "nombreCiudad",
      width: "80%",
    },
    {
      Header: "Acciones",
      width: "20%",
      Cell: ({ row }) => (
        <>
          <IconButtonTable
            title="Editar ciudad"
            icon={<FaEdit size={25} color={isAdmin ? "#ff8144" : "#91918e"} />}
            action={() => setValues(row.original)}
            disabled={!isAdmin}
          />
          <IconButtonTable
            title="Eliminar ciudad"
            icon={
              <FaTrashAlt size={25} color={isAdmin ? "#ff8144" : "#91918e"} />
            }
            action={() => deleteCiudad(row.original)}
            disabled={!isAdmin}
          />
        </>
      ),
    },
  ];

  const [ciudadSelected, setCiudadSelected] = useState({});
  const [values, setValues] = useState({
    id: ciudadSelected ? ciudadSelected.id : "",
    nombreCiudad: ciudadSelected ? ciudadSelected.nombreCiudad : "",
  });
  const [ciudades, setCiudades] = useState([]);
  const handleInputChange = (event) => {
    const { name, value } = event.target;

    setValues({
      ...values,
      [name]: value,
    });
  };
  const getAllCiudades = () => {
    axios
      .get("getAllCiudades")
      .then((res) => {
        if (res.status >= 200 && res.status < 300) {
          setCiudades(res.data);
        }
      })
      .catch((error) => {
        errorHandler(error, "Error al cargar las ciudades");
      });
  };

  useEffect(() => {
    getAllCiudades();
  }, [setCiudades]);
  const addCiudad = () => {
    axios
      .post("createCiudad", values)
      .then((res) => {
        if (res.status >= 200 && res.status < 300) {
          getAllCiudades();
          openSuccessToast("Se han guardado la ciudad");
          setValues({
            id: "",
            nombreCiudad: "",
          });
        }
      })
      .catch((error) => {
        let message = error.response?.data?.message
          ? error.response?.data?.message
          : "Error al crear la ciudad";
        errorHandler(error, message);
      });
  };
  const deleteCiudad = (ciudadToDelete) => {
    axios
      .post("deleteCiudad", ciudadToDelete)
      .then((res) => {
        if (res.status >= 200 && res.status < 300) {
          openSuccessToast("Se han borrado la ciudad");
          getAllCiudades();
        }
      })
      .catch((error) => {
        let message = error.response?.data?.message
          ? error.response?.data?.message
          : "Error al eliminar la ciudad";
        errorHandler(error, message);
      });
  };
  return (
    <>
      <div className="modal-dark-background" onClick={() => setIsOpen(false)} />
      <div className="modal-container">
        <div className="modal-zonas">
          <div className="modal-header">
            <h1 className="heading">Ciudades</h1>
          </div>
          <button className="closeBtn" onClick={() => setIsOpen(false)}>
            <RiCloseLine style={{ marginBottom: "-3px" }} />
          </button>
          <div className="modalContent">
            {isAdmin && (
              <div className="fields-container">
                <Textfield
                  name="nombreCiudad"
                  onChange={handleInputChange}
                  value={values.nombreCiudad}
                  text="Nombre ciudad"
                  type="text"
                />
                <button
                  type="submit"
                  className="btn_submit"
                  onClick={addCiudad}
                >
                  {"Guardar ciudad"}
                </button>
              </div>
            )}

            <Table columns={columns} data={ciudades} />
          </div>
        </div>
      </div>
    </>
  );
}
