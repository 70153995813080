import React, { useState } from "react";
import { RiCloseLine } from "react-icons/ri";
import Textfield from "../Textfield/Textfield";
import { openErrorToast, openSuccessToast } from "../../utils/errorToast";
import "./Modal.css";

export default function ModalPeajes({ setIsOpen, info }) {
  const [value, setValue] = useState(info?.value ? info.value : 0);
  const handleInputChange = (event) => {
    const { value } = event.target;
    if (value.match(/^[0-9,.]*$/)) {
      var val = value.replace(/(\..*)\./g, "$1");
      var x = Number(val.replace(/,/g, ""));
      var formatter = new Intl.NumberFormat("en-US", {
        minimumFractionDigits: 0,
      });
      setValue(formatter.format(x));
    } else {
      openErrorToast("No se pueden agregar letras ni signos en este apartado");
    }
  };

  const submitValue = () => {
    info.action(value.replace(/,/g, ""));
    setIsOpen(false);
  };
  return (
    <>
      <div className="modal-dark-background" onClick={() => setIsOpen(false)} />
      <div className="modal-container">
        <div className="modal-peajes">
          <div className="modal-header">
            <h1 className="heading">{info.text}</h1>
          </div>
          <button className="closeBtn" onClick={() => setIsOpen(false)}>
            <RiCloseLine style={{ marginBottom: "-3px" }} />
          </button>
          <div className="modalContent">
            <Textfield
              name="peaje"
              onChange={handleInputChange}
              value={value}
              text="Valor"
              type="text"
              className="form-input-modal"
            />
            <button type="submit" className="btn_submit" onClick={submitValue}>
              Guardar
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
