import React, { useState } from "react";
import axios from "../../utils/axios";
import Textfield from "../../components/Textfield/Textfield";
import "./LoginPage.css";
import { useNavigate } from "react-router-dom";
import Title from "../../components/Title/Title";
import { openErrorToast } from "../../utils/errorToast";
export default function LoginPage() {
  const navigate = useNavigate();
  const [values, setValues] = useState({
    cedula: "",
    contrasena: "",
  });
  const handleInputChange = (event) => {
    const { name, value } = event.target;

    setValues({
      ...values,
      [name]: value,
    });
  };
  const login = () => {
    let errorMessage = "Error: identificación o contraseña incorrecta";
    if (values.cedula !== "" || values.contrasena !== "") {
      axios
        .post("/auth/signin", values)
        .then((res) => {
          if (res.status >= 200 && res.status < 300) {
            localStorage.setItem("user", JSON.stringify(res.data));
            axios.defaults.headers.Authorization = `Bearer ${res.data.token}`;
            navigate("/home");
          }
        })
        .catch((error) => {
          let messageReturned = error?.response?.data?.message;
          openErrorToast(messageReturned ? messageReturned : errorMessage);
        });
    } else {
      openErrorToast(errorMessage);
    }
  };
  return (
    <div className="main-login-container">
      <div className="login-container">
        <Title text="Iniciar sesión" />
        {/* <p className="text-login">
          ¿No tienes cuenta? <Link to="/registro">Regístrate aquí</Link>
        </p> */}
        <div className="login-form-container">
          <Textfield
            name="cedula"
            onChange={handleInputChange}
            value={values.cedula}
            text="Identificación"
            type="text"
          />
          <Textfield
            name="contrasena"
            onChange={handleInputChange}
            value={values.contrasena}
            text="Contraseña"
            type="password"
          />
          <button type="submit" className="btn-submit" onClick={login}>
            Entrar
          </button>
        </div>
      </div>
    </div>
  );
}
