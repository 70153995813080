import React from "react";
import "./Modal.css";
import { RiCloseLine } from "react-icons/ri";
import { FaFileDownload } from "react-icons/fa";
import Table from "../Table/Table";
import IconButtonTable from "../IconButtonTable/IconButtonTable";

export default function ModalPacienteInCuenta({
  registros,
  setIsOpen,
  setPacienteSelected,
  download,
}) {
  const columns = [
    {
      Header: "Fecha",
      accessor: "fecha",
    },
    {
      Header: "Paciente",
      accessor: "paciente.nombrePaciente",
    },

    {
      Header: "Valor",
      accessor: "precioActual",
      Cell: ({ row }) => (
        <>
          $
          {row.original?.precioActual.toLocaleString(navigator.language, {
            minimumFractionDigits: 0,
          })}
        </>
      ),
    },
    {
      Header: "Conductor",
      accessor: "conductorForReport",
    },
    {
      Header: "Ruta",
      accessor: "ruta.nombreRuta",
    },
    {
      Header: "Tipo recorrido",
      accessor: "ruta.tipoRuta",
    },
  ];

  const handleClose = () => {
    setIsOpen(false);
    setPacienteSelected(null);
  };

  return (
    <>
      <div className="modal-dark-background" onClick={handleClose} />
      <div className="modal-container">
        <div className="modal-paciente-cuentas">
          <div className="modal-header">
            <div className="modal-header-title">
              <h1 className="heading">Paciente en cuentas</h1>
              <IconButtonTable
                title="Buscar paciente"
                icon={<FaFileDownload size={30} color="#ff8144" />}
                action={download}
              />
            </div>
          </div>
          <button className="closeBtn" onClick={handleClose}>
            <RiCloseLine style={{ marginBottom: "-3px" }} />
          </button>
          <div className="modalContent">
            <Table columns={columns} data={registros} />
          </div>
        </div>
      </div>
    </>
  );
}
