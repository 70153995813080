import React, { useEffect, useState } from "react";
import "./AutocompletePaciente.css";

export default function AutocompletePaciente({
  label,
  pholder,
  data,
  onSelected,
  onChange,
  resetValues,
  filterByName,
}) {
  const [suggestions, setSugesstions] = useState([]);
  const [isHideSuggs, setIsHideSuggs] = useState(false);
  const [selectedVal, setSelectedVal] = useState("");

  useEffect(() => {
    setSelectedVal("");
    var getValue = document.getElementById("autocomplete-pacientes");
    if (getValue.value != "") {
      getValue.value = "";
    }
  }, [resetValues]);
  const handler = (e) => {
    setSugesstions(
      filterByName
        ? data.filter((paciente) =>
            paciente.nombrePaciente.startsWith(e.target.value.toUpperCase())
          )
        : data.filter((paciente) =>
            paciente.numeroDocumento.startsWith(e.target.value)
          )
    );
  };

  const handleChange = (e) => {
    const input = e.target.value;
    setIsHideSuggs(input.length === 0 ? true : false);
    setSelectedVal(input);
    onChange(input);
  };

  const hideSuggs = (value) => {
    onSelected(value);
    setSelectedVal(value);
    setIsHideSuggs(true);
  };
  return (
    <div className="autocomplete-pacientes-container">
      <div className="form-autocomplete-pacientes">
        <input
          className="pacientes-input"
          placeholder=" "
          type="search"
          value={selectedVal}
          onChange={handleChange}
          onKeyUp={handler}
          name="autocomplete-pacientes"
          id="autocomplete-pacientes"
        />
        <label
          className="form-label-pacientes"
          htmlFor="autocomplete-pacientes"
        >
          {label}
        </label>
      </div>

      <div
        className="pacientes-suggestions"
        style={{ display: isHideSuggs ? "none" : "block" }}
      >
        {suggestions.map((item, idx) => (
          <div
            key={"" + item.numeroDocumento + idx}
            onClick={() => {
              hideSuggs(
                filterByName ? item.nombrePaciente : item.numeroDocumento
              );
            }}
          >
            {filterByName ? item.nombrePaciente : item.numeroDocumento}
          </div>
        ))}
      </div>
    </div>
  );
}
