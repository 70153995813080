import React, { useState } from "react";
import "./Modal.css";
import Textfield from "../Textfield/Textfield";
import { RiCloseLine } from "react-icons/ri";

const ModalCorteCuentas = ({ setIsOpen }) => {
  const [numeroCorte, setNumeroCorte] = useState("");

  const handleTextFieldChange = (e) => {
    setNumeroCorte(e.target.value);
  };

  const definirNumeroCorte = () => {
    localStorage.setItem("numeroCorte", numeroCorte);
    window.location.reload();
  };

  const corteActual = localStorage.getItem("numeroCorte");
  
  return (
    <>
      <div className="modal-dark-background" onClick={() => setIsOpen(false)} />
      <div className="modal-container">
        <div className="modal-corte-cuenta">
          <button className="closeBtn" onClick={() => setIsOpen(false)}>
            <RiCloseLine style={{ marginBottom: "-3px" }} />
          </button>
          <div className="modalContent-confirmation">
            <h1 className="heading">
              Ingresa el número de corte de cuentas por mes
            </h1>
            <div>
              <Textfield
                name="numeroCorte"
                value={numeroCorte}
                onChange={handleTextFieldChange}
                text="Número de corte"
                type="text"
              />
              <p style={{ textAlign: "left", margin: "15px 0px 0px" }}>Corte actual: <span style={{fontWeight: "bold"}}>{corteActual}</span> </p>
            </div>
            
            <div className="container-buttons-modal-conf">
              <button
                type="submit"
                className="btn-accept"
                onClick={definirNumeroCorte}
                disabled={numeroCorte === "" || isNaN(numeroCorte)}
              >
                Aceptar
              </button>
              <button
                type="submit"
                className="btn-cancel"
                onClick={() => setIsOpen(false)}
              >
                Cancelar
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ModalCorteCuentas;
