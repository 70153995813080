import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export default function AuthVerify(props) {
  let location = useLocation();
  const navigate = useNavigate();
  const redirectToPage = (path) => {
    navigate(path);
  };
  const parseJwt = (token) => {
    try {
      return JSON.parse(atob(token.split(".")[1]));
    } catch (e) {
      return null;
    }
  };

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));

    if (user) {
      const decodedJwt = parseJwt(user.token);

      if (decodedJwt.exp * 1000 < Date.now()) {
        props.logOut();
        redirectToPage("/login");
      }
    }
  }, [location]);
  return <div></div>;
}
