import { useState, useEffect, memo } from "react";
import { AiOutlineLeft,AiOutlineRight } from "react-icons/ai";

import { usePaginationPages } from "./usePaginationPages";
import "./Pagination.css";

function Pagination({ gotoPage, length, pageSize, setPageSize }) {
  const [perPage, setPerPage] = useState(pageSize);

  const {
    canGo,
    currentPage,
    pages,
    goTo,
    goNext,
    goPrev
  } = usePaginationPages({
    gotoPage,
    length,
    pageSize
  });

  useEffect(() => {
    setPageSize(Number(perPage));
  }, [perPage, setPageSize]);

  return (
    <div className="container-pagination">
      <button
        onClick={goPrev}
        disabled={!canGo.previous}
        className="btn-pagination"
      >
        <AiOutlineLeft color="#ff8144" size={20}/>
      </button>
      {pages.map((page, i) => (
        <button
          onClick={() => goTo(page)}
          key={i}
          style={{
            background: currentPage === page ? "#ff8144" : "none",
            color: currentPage === page ? "white" : "black"
          }}
          className="btn-pagination"
        >
          {page}
        </button>
      ))}
      <button
        onClick={goNext}
        disabled={!canGo.next}
        className="btn-pagination"
      >
        <AiOutlineRight color="#ff8144" size={20}/>
      </button>
      <select
        className="select-pagination"
        value={pageSize}
        onChange={(e) => setPerPage(e.target.value)}
      >
        {[10, 50, 100].map((pageSize) => (
          <option className="py-2" value={pageSize} key={pageSize}>
            {pageSize} / página
          </option>
        ))}
      </select>
    </div>
  );
}

export default memo(Pagination);
