import React, { useState, useEffect } from "react";
import "./Modal.css";
import { RiCloseLine } from "react-icons/ri";
import Textfield from "../Textfield/Textfield";
import { FaEdit, FaTrashAlt } from "react-icons/fa";
import Table from "../Table/Table";
import axios from "../../utils/axios";
import { errorHandler, openSuccessToast } from "../../utils/errorToast";
import IconButtonTable from "../IconButtonTable/IconButtonTable";

export default function ModalZonas({ setIsOpen }) {
  const user = JSON.parse(localStorage.getItem("user"));
  const isAdmin = user?.roles.includes("ROLE_ADMIN");
  const columns = [
    {
      Header: "Nombre zona",
      accessor: "nombreZona",
      width: "80%",
    },
    {
      Header: "Acciones",
      width: "20%",
      Cell: ({ row }) => (
        <>
          <IconButtonTable
            title="Editar zona"
            icon={<FaEdit size={25} color={isAdmin ? "#ff8144" : "#91918e"} />}
            action={() => setValues(row.original)}
            disabled={!isAdmin}
          />
          <IconButtonTable
            title="Eliminar zona"
            icon={
              <FaTrashAlt size={25} color={isAdmin ? "#ff8144" : "#91918e"} />
            }
            action={() => deleteZona(row.original)}
            disabled={!isAdmin}
          />
        </>
      ),
    },
  ];

  const [zonaSelected, setZonasSelected] = useState({});
  const [values, setValues] = useState({
    zonaId: zonaSelected ? zonaSelected.zonaId : "",
    nombreZona: zonaSelected ? zonaSelected.nombreZona : "",
  });
  const [zonas, setZonas] = useState([]);
  const handleInputChange = (event) => {
    const { name, value } = event.target;

    setValues({
      ...values,
      [name]: value,
    });
  };
  const getAllZonas = () => {
    axios
      .get("getAllZonas")
      .then((res) => {
        if (res.status >= 200 && res.status < 300) {
          setZonas(res.data);
        }
      })
      .catch((error) => {
        errorHandler(error, "Error al cargar las zonas");
      });
  };

  useEffect(() => {
    getAllZonas();
  }, [setZonas]);
  const addZona = () => {
    axios
      .post("createZona", values)
      .then((res) => {
        if (res.status >= 200 && res.status < 300) {
          getAllZonas();
          setValues({
            zonaId: "",
            nombreZona: "",
          });
          openSuccessToast("Se han guardado la zona");
        }
      })
      .catch((error) => {
        let message = error.response?.data?.message
          ? error.response?.data?.message
          : "Error al crear la zona";
        errorHandler(error, message);
      });
  };
  const deleteZona = (zonaToDelete) => {
    axios
      .post("deleteZona", zonaToDelete)
      .then((res) => {
        if (res.status >= 200 && res.status < 300) {
          openSuccessToast("Se han borrado la zona");
          getAllZonas();
        }
      })
      .catch((error) => {
        let message = error.response?.data?.message
          ? error.response?.data?.message
          : "Error al crear la zona";
        errorHandler(error, message);
      });
  };
  return (
    <>
      <div className="modal-dark-background" onClick={() => setIsOpen(false)} />
      <div className="modal-container">
        <div className="modal-zonas">
          <div className="modal-header">
            <h1 className="heading">Zonas</h1>
          </div>
          <button className="closeBtn" onClick={() => setIsOpen(false)}>
            <RiCloseLine style={{ marginBottom: "-3px" }} />
          </button>
          <div className="modalContent">
            {isAdmin && (
              <div className="fields-container">
                <Textfield
                  name="nombreZona"
                  onChange={handleInputChange}
                  value={values.nombreZona}
                  text="Nombre zona"
                  type="text"
                />
                <button type="submit" className="btn_submit" onClick={addZona}>
                  {"Guarda zona"}
                </button>
              </div>
            )}

            <Table columns={columns} data={zonas} />
          </div>
        </div>
      </div>
    </>
  );
}
