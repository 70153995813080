import React from "react";
import "./IconButtonTable.css";

export default function IconButtonTable({ title, icon, action, disabled }) {
  return (
    <button
      className={disabled ? "btn-icon-disabled" : "btn-icon"}
      title={title}
      onClick={() => action()}
      disabled={disabled}
    >
      {icon}
    </button>
  );
}
