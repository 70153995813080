import React, { useState, useEffect } from "react";
import axios from "../../utils/axios";
import { errorHandler, openSuccessToast } from "../../utils/errorToast";
import Textfield from "../Textfield/Textfield";
import "./FormConductor.css";

export default function FormConductor({
  conductor,
  setIsOpen,
  reloadConductores,
}) {
  const user = JSON.parse(localStorage.getItem("user"));
  const isAdmin = user?.roles.includes("ROLE_ADMIN");
  const isConductor = user?.roles.includes("ROLE_CONDUCTOR");
  const isConductorInterno = user?.roles.includes("ROLE_CONDUCTOR_INTERNO");

  const [values, setValues] = useState({
    empleadoId: conductor ? conductor.empleadoId : "",
    nombreEmpleado: conductor ? conductor.nombreEmpleado : "",
    tipoDocumento: conductor ? conductor.tipoDocumento : "",
    numeroDocumento: conductor ? conductor.numeroDocumento : "",
    dirEmpleado: conductor ? conductor.dirEmpleado : "",
    celEmpleado: conductor ? conductor.celEmpleado : "",
    correoElectronico: conductor ? conductor.correoElectronico : "",
    ciudad: conductor ? conductor.ciudad : "",
    placa: conductor ? conductor.placa : "",
    numeroCuenta: conductor ? conductor.numeroCuenta : "",
    tipoCuenta: conductor ? conductor.tipoCuenta : "",
    banco: conductor ? conductor.banco : "",
    zonas: conductor ? conductor.zonas : "",
    rol: conductor ? conductor.rol : "",
  });
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  const addConductor = () => {
    let url = "";
    if (conductor) {
      url = "editEmpleado";
    } else {
      url = "createEmpleado";
    }
    axios
      .post(url, values)
      .then((res) => {
        if (res.status >= 200 && res.status < 300) {
          openSuccessToast("Se ha guardado el conductor");
          if (setIsOpen) {
            setIsOpen(false);
            reloadConductores();
          } else {
            let newStorage = {
              ...user,
              ["empleado"]: values,
            };
            localStorage.setItem("user", JSON.stringify(newStorage));
          }
        }
      })
      .catch((error) => {
        let message = error.response?.data?.message
          ? error.response?.data?.message
          : "Error al crear conductor";

        errorHandler(error, message);
      });
  };

useEffect(() => {
    if (isConductor) {
      setValues({
        ...values,
        ["rol"]: "ROLE_CONDUCTOR",
      });
    } else if (isConductorInterno) {
      setValues({
        ...values,
        ["rol"]: "ROLE_CONDUCTOR_INTERNO",
      });
    }
  }
, []);  

  return (
    <div className="container-form-conductor">
      {isAdmin && (
        <div className="form-group-select-cc">
          <select
            className="form-input-select"
            placeholder=" "
            onChange={(e) => {
              setValues({
                ...values,
                ["rol"]: e.target.value,
              });
            }}
            value={values.rol}
          >
            <option key="conductor" value="ROLE_CONDUCTOR">
              CONDUCTOR
            </option>
            <option key="interno" value="ROLE_CONDUCTOR_INTERNO">
              CONDUCTOR INTERNO
            </option>
            <option value="" selected disabled hidden>
              Seleccione el rol
            </option>
          </select>
          <label htmlFor="rol" className="form-label-select">
            Rol
          </label>
        </div>
      )}
  
      <Textfield
        name="tipoDocumento"
        onChange={handleInputChange}
        value={values.tipoDocumento}
        text="Tipo de documento"
        type="text"
        disabled={isConductorInterno}
      />
      <Textfield
        name="numeroDocumento"
        onChange={handleInputChange}
        value={values.numeroDocumento}
        text="Número de documento"
        type="text"
        disabled={!isAdmin}
      />
      <Textfield
        name="nombreEmpleado"
        onChange={handleInputChange}
        value={values.nombreEmpleado}
        text="Nombre titular"
        type="text"
        disabled={!isAdmin}
      />
      <Textfield
        name="dirEmpleado"
        onChange={handleInputChange}
        value={values.dirEmpleado}
        text="Dirección"
        type="text"
        disabled={isConductorInterno}
      />
      <Textfield
        name="ciudad"
        onChange={handleInputChange}
        value={values.ciudad}
        text="Ciudad"
        type="text"
        disabled={isConductorInterno}
      />
      <Textfield
        name="celEmpleado"
        onChange={handleInputChange}
        value={values.celEmpleado}
        text="Celular"
        type="text"
        disabled={isConductorInterno}
      />
      <Textfield
        name="correoElectronico"
        onChange={handleInputChange}
        value={values.correoElectronico}
        text="Correo electrónico"
        type="text"
        disabled={isConductorInterno}
      />
      <Textfield
        name="placa"
        onChange={handleInputChange}
        value={values.placa}
        text="Placa"
        type="text"
        disabled={isConductorInterno}
      />
      <Textfield
        name="numeroCuenta"
        onChange={handleInputChange}
        value={values.numeroCuenta}
        text="Número de cuenta"
        type="text"
        disabled={isConductorInterno}
      />
      <Textfield
        name="tipoCuenta"
        onChange={handleInputChange}
        value={values.tipoCuenta}
        text="Tipo de cuenta"
        type="text"
        disabled={isConductorInterno}
      />
      <Textfield
        name="banco"
        onChange={handleInputChange}
        value={values.banco}
        text="Banco"
        type="text"
        disabled={isConductorInterno}
      />
      {user?.roles.includes("ROLE_ADMIN") && (
        <Textfield
          name="zonas"
          onChange={handleInputChange}
          value={values.zonas}
          text="Zonas"
          type="text"
        />
      )}
      {(isConductor || isAdmin) && <button type="submit" className="btn_submit" onClick={addConductor}>
        {conductor ? "Guardar titular" : "Agregar titular"}
      </button>}
    </div>
  );
}
