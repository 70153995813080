import React, { useState } from "react";
import { RiCloseLine } from "react-icons/ri";
import axios from "../../utils/axios";
import { errorHandler, openSuccessToast } from "../../utils/errorToast";
import Textarea from "../Textarea/Textarea";
import Textfield from "../Textfield/Textfield";
import "./Modal.css";
import { TabPanel, TabList, Tab, Tabs } from "react-tabs";

export default function ModalValueCuenta({ setIsOpen, reloadCuentas, cuenta }) {
  const user = JSON.parse(localStorage.getItem("user"));
  const editable = user?.roles.includes("ROLE_ADMIN");
  const isConductor = user?.roles.includes("ROLE_CONDUCTOR");
  const isConductorInterno = user?.roles.includes("ROLE_CONDUCTOR_INTERNO");
  const [values, setValues] = useState({
    cuentaCobroId: cuenta ? cuenta.cuentaCobroId : "",
    valorTotal: cuenta
      ? new Intl.NumberFormat("en-US", { minimumFractionDigits: 0 }).format(
          cuenta.valorTotal
        )
      : "",
    observaciones: cuenta ? cuenta.observaciones : "",
    estado: cuenta ? cuenta.estado : "",
    observacionesUsuario: cuenta ? cuenta.observacionesUsuario : "",
    observacionesConductor: cuenta ? cuenta.observacionesConductor : "",
  });
  const observaciones = [cuenta.observaciones, cuenta.observacionesConductor];
  const [activeTab, setActiveTab] = useState(0);
  const handleTabSelect = (index) => {
    setActiveTab(index);
  };
  const handleInputChange = (event) => {
    const { name, value } = event.target;

    if (name === "valorTotal") {
      var val = value.replace(/(\..*)\./g, "$1");
      var x = Number(val.replace(/,/g, ""));
      var formatter = new Intl.NumberFormat("en-US", {
        minimumFractionDigits: 0,
      });

      setValues({
        ...values,
        [name]: formatter.format(x),
      });
    } else {
      setValues({
        ...values,
        [name]: value,
      });
    }
  };
  const changeValor = () => {
    const data = {
      ...values,
      ["valorTotal"]: values.valorTotal.replace(/,/g, ""),
      ["observacionesUsuario"]: user.empleado.nombreEmpleado,
    };
    axios
      .post("changeValorTotal", data)
      .then((res) => {
        if (res.status >= 200 && res.status < 300) {
          setIsOpen(false);
          reloadCuentas();
          openSuccessToast("Se ha agregado la observación");
        }
      })
      .catch((error) => {
        errorHandler(
          error,
          "Error al cambiar el valor total de la cuenta de cobro"
        );
      });
  };
  return (
    <>
      <div className="modal-dark-background" onClick={() => setIsOpen(false)} />
      <div className="modal-container">
        <div
          className="modal-value-cuenta"
          style={
            isConductor || isConductorInterno || !cuenta.observacionesConductor
              ? { height: "450px", width: "420px" }
              : null
          }
        >
          <div className="modal-header">
            <h1 className="heading">Cambiar valor total</h1>
          </div>
          <button className="closeBtn" onClick={() => setIsOpen(false)}>
            <RiCloseLine style={{ marginBottom: "-3px" }} />
          </button>
          <div className="modalContent">
            <Textfield
              name="valorTotal"
              onChange={handleInputChange}
              value={values.valorTotal}
              text="Valor total"
              type="text"
              className="form-input-modal"
              disabled={!editable}
            />
            <Tabs selectedIndex={activeTab} onSelect={handleTabSelect}>
              {cuenta.observacionesConductor ? (
                <TabList>
                  <Tab>Observaciones Admin</Tab>
                  <Tab>Observaciones Titular</Tab>
                </TabList>
              ) : null}

              {Array.from(observaciones).map((e, index) => {
                return (
                  <TabPanel key={index}>
                    <Textarea
                      name="observaciones"
                      onChange={handleInputChange}
                      value={activeTab === 0 ? values.observaciones : e}
                      text={"Observaciones"}
                      rows={5}
                      disabled={activeTab === 0 && editable ? false : true}
                    />
                  </TabPanel>
                );
              })}
            </Tabs>
            <div style={{ height: "10px" }}>
              {activeTab === 0 &&
                (values.observacionesUsuario || cuenta.observaciones) && (
                  <div>
                    Observación hecha por:{" "}
                    <span style={{ fontWeight: "bold" }}>
                      {cuenta.observacionesUsuario}
                    </span>
                  </div>
                )}
            </div>
            {editable && (
              <button
                type="submit"
                className="btn_submit"
                onClick={changeValor}
              >
                Guardar
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
