import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import ButtonHome from "../../components/ButtonHome/ButtonHome";
import CuentaCobroFields from "../../components/CuentaCobroFields/CuentaCobroFields";
import CuentaCobroTable from "../../components/CuentaCobroTable/CuentaCobroTable";
import ModalConfirmation from "../../components/Modal/ModalConfirmation";
import Loading from "../../components/Loading/Loading";
import Title from "../../components/Title/Title";
import axios from "../../utils/axios";
import { errorHandler, openSuccessToast } from "../../utils/errorToast";
import "./CuentaCobroPage.css";
export default function CuentaCobroPage() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("user"));
  const isConductorInterno = user?.roles.includes("ROLE_CONDUCTOR_INTERNO");
  const [isLoading, setIsLoading] = useState(false);
  const [idCuenta, setIdCuenta] = useState(
    state?.data?.cuentaCobroId ? state?.data.cuentaCobroId : null
  );

  const [observacionesConductor, setObservacionesConductor] = useState(
    state?.data.observacionesConductor || null
  );

  useEffect(() => {
    const savedObservaciones = localStorage.getItem("observacionesConductor");
    if (savedObservaciones) {
      setObservacionesConductor(savedObservaciones);
    } else {
      setObservacionesConductor(state?.data.observacionesConductor || "");
    }
  }, [state?.data.observacionesConductor]);

  const [isChecked, setIsChecked] = useState(false);
  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    setObservacionesConductor(value);
  };

  const [eps, setEps] = useState(new Set());
  const [estadoCuenta, setEstadoCuenta] = useState(
    state?.data ? state?.data?.estado : null
  );
  const [conductorCuenta, setConductorCuenta] = useState(
    state?.data ? state?.data?.conductor.empleadoId : null
  );
  const [dataCuenta, setDataCuenta] = useState(
    state?.data ? state?.data.registrosCuenta : []
  );
  const [valorTotal, setValorTotal] = useState();
  const [dataForTab, setDataForTab] = useState([]);
  const [summary, setSummary] = useState("");
  const [indexRow, setIndexRow] = useState(0);
  const [selectedTab, setSelectedTab] = useState(0);
  const [peajes, setPeajes] = useState(
    state?.data?.cuentaCobroId ? state?.data.valorPeajes : 0
  );
  const [impulsos, setImpulsos] = useState(
    state?.data?.cuentaCobroId ? state?.data.valorImpulsos : 0
  );
  const [isModalConfirmationOpen, setIsModalConfirmationOpen] = useState(false);

  const editable =
    estadoCuenta === "Borrador" ||
    estadoCuenta === null ||
    user?.roles.includes("ROLE_ADMIN");

  //Review to improve performance
  const addRegistryToTable = (dataToAdd, dates) => {
    let dataToSave = [];
    let actualIndex = indexRow;
    for (let date of dates) {
      dataToAdd = {
        ...dataToAdd,
        ["index"]: actualIndex,
        ["fecha"]: date.format("DD/MM/YYYY"),
      };
      dataToSave.push(dataToAdd);
      actualIndex = actualIndex + 1;
      if (!eps.has(dataToAdd.paciente.eps)) {
        setEps((prevEps) => new Set(prevEps).add(dataToAdd.paciente.eps));
      }
      if (dataForTab.length === 0) {
        setDataForTab(dataToAdd);
      }
    }
    setDataCuenta((values) => [...values, ...dataToSave]);
    setIndexRow(actualIndex);
    getSummary();
  };
  useEffect(() => {
    getSummary();
    filterTableTab(selectedTab);
  }, [dataCuenta]);

  //useEffect to handle inital render of filtered data in table
  useEffect(() => {
    if (dataForTab.length === 0) {
      filterTableTab(selectedTab);
    }
  }, [eps]);
  const handleSaveCuenta = (newEstado) => {
    const user = JSON.parse(localStorage.getItem("user"));
    const isAdmin = user?.roles.includes("ROLE_ADMIN");

    if (user) {
      let empleadoId;
      if (conductorCuenta) {
        empleadoId = conductorCuenta;
      } else {
        empleadoId = user?.empleado?.empleadoId;
      }
      setIsLoading(true);
      let data = {
        registrosCuenta: dataCuenta,
        conductor: { empleadoId: empleadoId },
        estado: isAdmin && estadoCuenta ? estadoCuenta : newEstado,
        cuentaCobroId: idCuenta,
        valorPeajes: peajes,
        valorImpulsos: impulsos,
        observacionesConductor: isChecked ? observacionesConductor : null,
      };
      let message =
        newEstado === "Borrador"
          ? "Se ha guardado la cuenta de cobro"
          : "Se ha enviado la cuenta de cobro";

      if (isChecked) {
        localStorage.setItem("observacionesConductor", observacionesConductor);
      } else {
        setObservacionesConductor(
          localStorage.getItem("observacionesConductor")
        );
      }

      setIsChecked(false);
      axios
        .post("createCuentaCobro", data)
        .then((res) => {
          if (res.status >= 200 && res.status < 300) {
            setIsLoading(false);
            setIdCuenta(res.data.cuentaCobroId);
            setEstadoCuenta(res.data.estado);
            openSuccessToast(message);
            setDataCuenta(res.data.registrosCuenta);
            setConductorCuenta(res.data.conductor.empleadoId);
          }
        })
        .catch((error) => {
          setIsLoading(false);
          errorHandler(error, "Error crear la cuenta de cobro");
        });
    } else {
      navigate("/login");
    }
  };
  const handleSendCuenta = () => {
    setIsModalConfirmationOpen(true);
  };
  const onClickAcceptModal = () => {
    handleSaveCuenta("Enviada");
  };
  const handleRemoveRegister = (row) => {
    let comparator;
    if (row.registroId) {
      comparator = (element) => element.registroId === row.registroId;
    } else {
      comparator = (element) => element.index === row.index;
    }
    const indexFound = dataCuenta.findIndex(comparator);
    if (indexFound === 0) {
      dataCuenta.shift();
    } else {
      dataCuenta.splice(indexFound, 1);
    }

    filterTableTab(selectedTab);
    getSummary();
  };
  const filterTableTab = (epsIndex) => {
    setSelectedTab(epsIndex);
    let epsValue = Array.from(eps)[epsIndex];
    let dataCuentaFiltered = dataCuenta.filter(
      (data) => data.paciente?.eps === epsValue
    );
    if (dataCuentaFiltered.length === 0) {
      setSelectedTab(0);
      epsValue = Array.from(eps)[epsIndex - 1];
      dataCuentaFiltered = dataCuenta.filter(
        (data) => data.paciente?.eps === epsValue
      );
    }
    setDataForTab(dataCuentaFiltered);
  };

  const getSummary = () => {
    let summaryMap = new Map();
    let epsSet = new Set();
    let total = 0;

    for (let i = 0; i < dataCuenta.length; i++) {
      epsSet = new Set(epsSet).add(dataCuenta[i].paciente?.eps);
      total += dataCuenta[i].precioActual;

      let key = dataCuenta[i].ruta?.nombreRuta;

      if (summaryMap.has(key)) {
        let actualValues = summaryMap.get(key);
        let newValues = {
          amount: actualValues.amount + 1,
          value: actualValues.value + dataCuenta[i]?.precioActual,
        };
        summaryMap.set(key, newValues);
      } else {
        let newValues = {
          amount: 1,
          value: dataCuenta[i]?.precioActual,
        };
        summaryMap.set(key, newValues);
      }
    }
    let summaryText = "";
    for (let [key, value] of summaryMap) {
      summaryText =
        summaryText +
        "\u2022 Cantidad de viajes a " +
        key +
        ": " +
        value.amount;
      if (!isConductorInterno) {
        summaryText =
          summaryText +
          " con un total de: $" +
          value?.value?.toLocaleString(navigator.language, {
            minimumFractionDigits: 0,
          }) +
          "\n";
      }
    }
    setValorTotal(total + Number(peajes) + Number(impulsos));
    setEps(epsSet);
    setSummary(summaryText);
    if (selectedTab > dataForTab.length) {
      setSelectedTab(0);
    }
  };

  const addPeajes = (valorPeaje) => {
    setPeajes(valorPeaje);
    setValorTotal(valorTotal - Number(peajes) + Number(valorPeaje));
  };

  const addImpulsos = (valorImpulsos) => {
    setImpulsos(valorImpulsos);
    setValorTotal(valorTotal - Number(impulsos) + Number(valorImpulsos));
  };

  return (
    <div className="main-container-cuenta-cobro">
      <Title text="Cuenta de cobro" />
      <ButtonHome />
      <div className="container-cuenta-cobro">
        {editable && (
          <CuentaCobroFields
            key={idCuenta}
            handleAddValues={addRegistryToTable}
            setPeajes={addPeajes}
            setImpulsos={addImpulsos}
            peajeCuenta={peajes}
            impulsosCuenta={impulsos}
          />
        )}
        {eps.size > 0 && (
          <Tabs
            selectedIndex={selectedTab}
            onSelect={(index) => filterTableTab(index)}
          >
            <TabList>
              {Array.from(eps).map((e) => {
                return <Tab key={e}>{e}</Tab>;
              })}
            </TabList>
            {Array.from(eps).map((e) => {
              return (
                <TabPanel key={e}>
                  <CuentaCobroTable
                    data={dataForTab}
                    observacionesConductor={observacionesConductor}
                    onClickSave={handleSaveCuenta}
                    isChecboxChecked={isChecked}
                    handleInputChange={handleInputChange}
                    handleCheckboxChange={handleCheckboxChange}
                    onClickSend={handleSendCuenta}
                    valorTotal={valorTotal}
                    removeRow={handleRemoveRegister}
                    summary={summary}
                    estado={estadoCuenta}
                  />
                </TabPanel>
              );
            })}
          </Tabs>
        )}
        {valorTotal > 0 && (
          <section className="container-text-cuenta-cobro">
            <p className="sumary-text-cuenta-cobro">{summary}</p>
            <p className="totalvalues-text-cuenta-cobro">
              {peajes > 0 &&
                "Valor a pagar en peajes: $" +
                  Number(peajes).toLocaleString(navigator.language, {
                    minimumFractionDigits: 0,
                  }) +
                  "\n"}
              {impulsos > 0 &&
                "Valor a pagar en impulsos: $" +
                  Number(impulsos).toLocaleString(navigator.language, {
                    minimumFractionDigits: 0,
                  }) +
                  "\n"}
              Valor total a pagar $
              {valorTotal.toLocaleString(navigator.language, {
                minimumFractionDigits: 0,
              })}
            </p>
          </section>
        )}
      </div>
      {isLoading && <Loading />}
      {isModalConfirmationOpen && (
        <ModalConfirmation
          setIsOpen={setIsModalConfirmationOpen}
          text="¿Está seguro/a de enviar la cuenta de cobro?"
          onClickAccept={onClickAcceptModal}
        />
      )}
    </div>
  );
}
