import React from "react";
import "./Textarea.css";
// import "../Textfield/Textfield.css"

export default function Textarea({
  name,
  onChange,
  value,
  text,
  disabled,
  rows,
}) {
  return (
    <div className="form-group-area">
      <textarea
        className="form-input-area"
        name={name}
        placeholder={text}
        onChange={onChange}
        value={value}
        id={name}
        maxLength={600}
        disabled={disabled}
        rows={rows}
      />
    </div>
  );
}
