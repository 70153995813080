import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
// import App from "./App";
// import FormConductorPage from "./pages/FormConductor/FormConductorPage";
// import CuentaCobroPage from "./pages/CuentaCobroPage/CuentaCobroPage";
import reportWebVitals from "./reportWebVitals";
import routes from "./routes";
import { BrowserRouter as Router } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Router>{routes}</Router>
    {/* <FormConductorPage /> */}
    {/* <CuentaCobroPage /> */}
    <ToastContainer
      position="bottom-center"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="light"
    />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
