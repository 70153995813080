import { toast } from "react-toastify";
export const openErrorToast = (message) => {
  toast.error(message, {
    position: "bottom-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });
};
export const openSuccessToast = (message) => {
  toast.success(message, {
    position: "bottom-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });
};
export const errorHandler = (error, message) => {
  if (error?.response?.status === 403) {
    openErrorToast("Error: Debe iniciar sesión primero");
  } else {
    openErrorToast(message);
  }
};
