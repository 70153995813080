import React, { useState } from "react";
import "./Modal.css";
import { RiCloseLine } from "react-icons/ri";
import Textfield from "../Textfield/Textfield";
import axios from "../../utils/axios";
import { errorHandler, openSuccessToast} from "../../utils/errorToast";

export default function ModalChangePassword({ setIsOpen }) {
  const [values, setValues] = useState({});
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  const changePassword = () => {
    if (values.contrasena1 === values.contrasena2) {
      const data = {
        contrasena: values.contrasena2,
      };
      axios
        .post("/auth/changePassword", data)
        .then((res) => {
          if (res.status >= 200 && res.status < 300) {
            setIsOpen(false);
            openSuccessToast("Se ha cambiado la contraseña")
          }
        })
        .catch((error) => {
          errorHandler(error, "Error al cambiar la contraseña");
        });
    } else {
      errorHandler({}, "Las contraseñas no coinciden");
    }
  };
  return (
    <>
      <div className="modal-dark-background" onClick={() => setIsOpen(false)} />
      <div className="modal-container">
        <div className="modal-change-password">
          <div className="modal-header">
            <h1 className="heading">Cambiar contraseña</h1>
          </div>
          <button className="closeBtn" onClick={() => setIsOpen(false)}>
            <RiCloseLine style={{ marginBottom: "-3px" }} />
          </button>
          <div className="modalContent">
            <Textfield
              name="contrasena1"
              onChange={handleInputChange}
              value={values.contrasena1}
              text="Ingrese la nueva contraseña"
              type="password"
            />
            <Textfield
              name="contrasena2"
              onChange={handleInputChange}
              value={values.contrasena2}
              text="Repita la nueva contraseña"
              type="password"
            />
            <button
              type="submit"
              className="btn_submit"
              onClick={changePassword}
            >
              Guardar
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
