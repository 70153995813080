import React, { useState } from "react";
import "./Modal.css";
import { RiCloseLine } from "react-icons/ri";

export default function ModalConfirmation({ setIsOpen, text, onClickAccept }) {
  const handleClickAccept = () => {
    setIsOpen(false);
    onClickAccept();
  };
  return (
    <>
      <div className="modal-dark-background" onClick={() => setIsOpen(false)} />
      <div className="modal-container">
        <div className="modal-confirmation">
          <button className="closeBtn" onClick={() => setIsOpen(false)}>
            <RiCloseLine style={{ marginBottom: "-3px" }} />
          </button>
          <div className="modalContent-confirmation">
            <h1 className="heading">{text}</h1>
            <div className="container-buttons-modal-conf">
              <button
                type="submit"
                className="btn-accept"
                onClick={handleClickAccept}
              >
                Aceptar
              </button>
              <button
                type="submit"
                className="btn-cancel"
                onClick={() => setIsOpen(false)}
              >
                Cancelar
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
