import React, { useEffect, useState } from "react";
import Title from "../../components/Title/Title";
import "./PacientesPage.css";
import Table from "../../components/Table/Table";
import { FaEdit, FaEyeSlash, FaEye } from "react-icons/fa";
import { RiUserSearchFill, RiHealthBookFill } from "react-icons/ri";
import axios from "../../utils/axios";
import IconButtonTable from "../../components/IconButtonTable/IconButtonTable";
import ModalPacientes from "../../components/Modal/ModalPacientes";
import {
  errorHandler,
  openErrorToast,
  openSuccessToast,
} from "../../utils/errorToast";
import ButtonHome from "../../components/ButtonHome/ButtonHome";
import Loading from "../../components/Loading/Loading";
import ModalPacienteInCuenta from "../../components/Modal/ModalPacienteInCuenta";
import ModalPacientesRepetidos from "../../components/Modal/ModalPacientesRepetidos";

export default function ConductoresPage() {
  const [pacientes, setPacientes] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalInCuentaOpen, setIsModalInCuentaOpen] = useState(false);
  const [pacienteInCuentas, setPacienteInCuentas] = useState([]);
  const [isModalRepetidosOpen, setIsModalRepetidosOpen] = useState(false);
  const [pacientesRepetidos, setPacientesRepetidos] = useState([]);
  const [pacienteSinRepetir, setPacienteSinRepetir] = useState([]);
  const [pacienteToEdit, setPacienteToEdit] = useState(null);
  const [pacienteSelected, setPacienteSelected] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const user = JSON.parse(localStorage.getItem("user"));
  const isAdmin = user?.roles.includes("ROLE_ADMIN");
  const columns = [
    // {
    //   Header: "Id",
    //   accessor: "id",
    // },
    {
      Header: "Nombre",
      accessor: "nombrePaciente",
    },
    {
      Header: "Tipo de documento",
      accessor: "tipoDocumento",
    },
    {
      Header: "Número documento",
      accessor: "numeroDocumento",
    },
    {
      Header: "EPS",
      accessor: "eps",
    },
    {
      Header: "Estado paciente",
      accessor: "estadoPaciente",
    },
    {
      Header: "Preguntar diálisis",
      accessor: "askForDialisis",
    },
    {
      Header: "¿Es diálisis?",
      accessor: "isDialisis",
    },
    {
      Header: "Acciones",
      Cell: ({ row }) => (
        <>
          {isAdmin && (
            <IconButtonTable
              title="Editar paciente"
              icon={
                <FaEdit size={25} color={isAdmin ? "#ff8144" : "#91918e"} />
              }
              action={() => handleEdit(row.original)}
              disabled={!isAdmin}
            />
          )}
          {isAdmin && (
            <IconButtonTable
              title="Habilitar/Deshabilitar paciente"
              icon={
                row.original.estadoPaciente === "Deshabilitado" ? (
                  <FaEye size={25} color={isAdmin ? "#ff8144" : "#91918e"} />
                ) : (
                  <FaEyeSlash
                    size={25}
                    color={isAdmin ? "#ff8144" : "#91918e"}
                  />
                )
              }
              action={() => disablePaciente(row.original)}
              disabled={!isAdmin}
            />
          )}
          {isAdmin && (
            <IconButtonTable
              title="Habilitar/Deshabilitar diálisis"
              icon={
                <RiHealthBookFill
                  size={25}
                  color={
                    row.original.askForDialisis === null ||
                    row.original.askForDialisis === "Habilitado"
                      ? "#ff5722"
                      : "#8bc34a"
                  }
                />
              }
              action={() => changeAskForDialisis(row.original)}
              disabled={!isAdmin}
            />
          )}

          <IconButtonTable
            title="Ver paciente en cuentas"
            icon={<RiUserSearchFill size={25} color="#ff8144" />}
            action={() => handleGetPacienteInCuenta(row.original)}
          />
        </>
      ),
    },
  ];

  useEffect(() => {
    getAllPacientes();
  }, [setPacientes]);
  const getAllPacientes = () => {
    setIsLoading(true);
    axios
      .get("getAllPacientes")
      .then((res) => {
        if (res.status >= 200 && res.status < 300) {
          setPacientes(res.data);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        errorHandler(error, "Error al cargar pacientes");
        setIsLoading(false);
      });
  };
  const handleEdit = (data) => {
    setPacienteToEdit(data);
    setIsModalOpen(true);
  };

  const handleGetPacienteInCuenta = (data) => {
    setIsLoading(true);
    axios
      .post("getPacienteInCuenta", data)
      .then((res) => {
        if (res.status >= 200 && res.status < 300) {
          setIsLoading(false);
          if (res.data.length == 0) {
            errorHandler(
              {},
              "No se encontró el paciente en ninguna cuenta de cobro"
            );
          } else {
            setPacienteInCuentas(res.data);
            setPacienteSelected(data);
            setIsModalInCuentaOpen(true);
          }
        }
      })
      .catch((error) => {
        errorHandler(error, "Error al cargar el paciente");
        setIsLoading(false);
      });
  };

  const disablePaciente = (data) => {
    axios
      .post("disablePaciente", data)
      .then((res) => {
        if (res.status >= 200 && res.status < 300) {
          getAllPacientes();
        }
      })
      .catch((error) => {
        errorHandler(error, "Error al cambiar el estado del paciente");
      });
  };

  const uploadPacientes = (event) => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append("file", event.target.files[0]);

    axios
      .post("uploadPacientes", formData)
      .then((res) => {
        if (
          res.data.pacientesRepetidos.length > 0 &&
          res.data.pacientesAgregados.length > 0
        ) {
          setPacientesRepetidos(res.data.pacientesRepetidos);
          setPacienteSinRepetir(res.data.pacientesAgregados);
          openSuccessToast("Se han cargado los pacientes");
          setIsModalRepetidosOpen(true);
        } else if (
          res.data.pacientesRepetidos.length > 0 &&
          res.data.pacientesAgregados.length == 0
        ) {
          setPacientesRepetidos(res.data.pacientesRepetidos);
          setIsModalRepetidosOpen(true);
          openErrorToast("No se han cargado los pacientes");
        } else {
          openSuccessToast("Se han cargado los pacientes");
        }
        setIsLoading(false);
        getAllPacientes();
      })
      .catch((error) => {
        openErrorToast("Error en el formato del archivo");
        setIsLoading(false);
        errorHandler(error);
      });
  };

  const downloadPacientes = () => {
    setIsLoading(true);
    const actualRows = pacientes.map((row) => row.original);
    axios
      .post("downloadPacientes", pacientes)
      .then((res) => {
        if (res.status >= 200 && res.status < 300) {
          setIsLoading(false);
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "pacientes.csv");
          document.body.appendChild(link);
          link.click();
          link.remove();
        }
      })
      .catch((error) => {
        setIsLoading(false);
        errorHandler(error, "Error al descargar los pacientes");
      });
  };

  const downloadPacientesInCuentas = () => {
    setIsLoading(true);
    axios
      .post("downloadPacienteInCuenta", pacienteSelected)
      .then((res) => {
        if (res.status >= 200 && res.status < 300) {
          setIsLoading(false);
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "pacienteInCuentas.csv");
          document.body.appendChild(link);
          link.click();
          link.remove();
        }
      })
      .catch((error) => {
        setIsLoading(false);
        errorHandler(
          error,
          "Error al descargar las cuentas en las que está el paciente"
        );
      });
  };

  const changeAskForDialisis = (data) => {
    axios
      .post("changeAskForDialisis", data)
      .then((res) => {
        if (res.status >= 200 && res.status < 300) {
          let message = "";
          let response = res.data.askForDialisis;
          if (response) {
            message = "habilitado";
          } else {
            message = "deshabilitado";
          }
          openSuccessToast(
            "Se ha " + message + " la pregunta de diálisis del paciente"
          );
          getAllPacientes();
        }
      })
      .catch((error) => {
        let message = error.response?.data?.message
          ? error.response?.data?.message
          : "Error al crear paciente";
        errorHandler(error, message);
      });
  };

  return (
    <div className="container-pacientes">
      <Title text="Pacientes" />
      <ButtonHome />
      <div className="container-buttons-pacientes">
        <button
          type="submit"
          className="btn-add-paciente"
          onClick={() => {
            setPacienteToEdit(null);
            setIsModalOpen(true);
          }}
        >
          Agregar paciente
        </button>
        <label htmlFor="file" className="btn-add-paciente">
          Cargar pacientes
        </label>
        <input type="file" id="file" onChange={(e) => uploadPacientes(e)} />
        {isAdmin && (
          <button
            type="submit"
            className="btn-load-cuentas"
            onClick={downloadPacientes}
          >
            Descargar Pacientes
          </button>
        )}
      </div>

      <div className="container-pacientes-table">
        <section>
          <Table columns={columns} data={pacientes} />
        </section>
        {isModalOpen && (
          <ModalPacientes
            setIsOpen={setIsModalOpen}
            reloadPacientes={getAllPacientes}
            paciente={pacienteToEdit}
          />
        )}
        {isModalInCuentaOpen && (
          <ModalPacienteInCuenta
            registros={pacienteInCuentas}
            setIsOpen={setIsModalInCuentaOpen}
            setPacienteSelected={setPacienteSelected}
            pacienteSelected={pacienteSelected}
            download={downloadPacientesInCuentas}
          />
        )}
        {isModalRepetidosOpen && (
          <ModalPacientesRepetidos
            setIsOpen={setIsModalRepetidosOpen}
            pacientesRepetidos={pacientesRepetidos}
            pacientesSinRepetir={pacienteSinRepetir}
          />
        )}
      </div>
      {isLoading && <Loading />}
    </div>
  );
}
