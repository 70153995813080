import React, { useEffect, useState } from "react";
import Title from "../../components/Title/Title";
import "./ViewUsuariosPage.css";
import Table from "../../components/Table/Table";
import { FaEdit } from "react-icons/fa";
import { FcOk, FcCancel } from "react-icons/fc";
import { MdLockReset } from "react-icons/md";
import axios from "../../utils/axios";
import IconButtonTable from "../../components/IconButtonTable/IconButtonTable";
import ModalEditUser from "../../components/Modal/ModalEditUser";
import { errorHandler, openSuccessToast } from "../../utils/errorToast";
import ButtonHome from "../../components/ButtonHome/ButtonHome";
import Loading from "../../components/Loading/Loading";

export default function ViewUsuariosPage() {
  const [users, setUsers] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userToEdit, setUserToEdit] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const columns = [
    {
      Header: "Tipo de documento",
      accessor: "empleadoDto.tipoDocumento",
    },
    {
      Header: "Número documento",
      accessor: "cedula",
    },
    {
      Header: "Nombre",
      accessor: "empleadoDto.nombreEmpleado",
    },
    {
      Header: "Rol",
      accessor: "rol",
    },
    {
      Header: "Estado",
      accessor: "estado",
    },
    {
      Header: "Acciones",
      Cell: ({ row }) => (
        <>
          <IconButtonTable
            title="Editar usuario"
            icon={<FaEdit size={25} color="#ff8144" />}
            action={() => handleEditUser(row.original)}
          />
          <IconButtonTable
            title="Restablecer contraseña"
            icon={<MdLockReset size={25} color="#ff8144" />}
            action={() => handleResetPassword(row.original?.empleadoDto)}
          />
          {row.original?.estado === "Habilitado" ? (
            <IconButtonTable
              title="Deshabilitar usuario"
              icon={<FcCancel size={25} color="#ff8144" />}
              action={() => changeStatus(row.original, "Deshabilitado")}
            />
          ) : (
            <IconButtonTable
              title="Habilitar usuario"
              icon={<FcOk size={25} color="#ff8144" />}
              action={() => changeStatus(row.original, "Habilitado")}
            />
          )}
        </>
      ),
    },
  ];

  useEffect(() => {
    getAllUsers();
  }, [setUsers]);
  const getAllUsers = () => {
    setIsLoading(true);
    axios
      .get("getAllUsers")
      .then((res) => {
        if (res.status >= 200 && res.status < 300) {
          setIsLoading(false);
          setUsers(res.data);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        errorHandler(error, "Error al cargar los usuarios");
      });
  };
  const handleEditUser = (data) => {
    setUserToEdit(data);
    setIsModalOpen(true);
  };
  const changeStatus = (data, estado) => {
    setIsLoading(true);
    data = { ...data, ["estado"]: estado };
    axios
      .post("changeUserStatus", data)
      .then((res) => {
        if (res.status >= 200 && res.status < 300) {
          openSuccessToast("Se cambió el estado del usuario a: " + estado);
          setIsLoading(false);
          getAllUsers();
        }
      })
      .catch((error) => {
        setIsLoading(false);
        errorHandler(error, "Error al restablecer la contraseña");
      });
  };
  const handleResetPassword = (data) => {
    setIsLoading(true);
    axios
      .post("auth/resetPassword", data)
      .then((res) => {
        if (res.status >= 200 && res.status < 300) {
          openSuccessToast("Se restableció la contraseña del usuario");
          setIsLoading(false);
          getAllUsers();
        }
      })
      .catch((error) => {
        setIsLoading(false);
        errorHandler(error, "Error al restablecer la contraseña");
      });
  };
  return (
    <div className="container-users">
      <Title text="Usuarios" />
      <ButtonHome />
      <div className="container-users-table">
        <section>
          <Table columns={columns} data={users} />
        </section>
      </div>
      {isLoading && <Loading />}
      {isModalOpen && (
        <ModalEditUser
          setIsOpen={setIsModalOpen}
          reloadUsers={getAllUsers}
          userToEdit={userToEdit}
        />
      )}
    </div>
  );
}
