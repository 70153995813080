import React from "react";
import { RiCloseLine } from "react-icons/ri";

const ModalPacientesRepetidos = ({
  pacientesRepetidos,
  pacientesSinRepetir,
  setIsOpen,
}) => {
  return (
    <>
      <div className="modal-dark-background" onClick={() => setIsOpen(false)} />
      <div className="modal-container">
        <div className="modal-paciente-repetido-container">
          <div className="modal-header">
            <h1 className="heading">Paciente repetidos</h1>
          </div>
          <button className="closeBtn" onClick={() => setIsOpen(false)}>
            <RiCloseLine style={{ marginBottom: "-3px" }} />
          </button>
          <div className="modal-paciente-repetido">
            <strong>
              No se agregaron los pacientes.
              <br />
              Los pacientes con los siguientes documentos ya existen:
            </strong>
            {[...new Set(pacientesRepetidos)].map((paciente, index) => (
              <div key={index}>{paciente}</div>
            ))}
            {pacientesSinRepetir.length > 0 && (
              <>
                <strong>
                  Se agregaron los pacientes con los siguientes documentos:{" "}
                </strong>
                {pacientesSinRepetir.map((paciente, index) => (
                  <div key={index}>{paciente}</div>
                ))}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ModalPacientesRepetidos;
