import React, { useEffect, useState } from "react";
import "./AutocompletePaciente.css";

export default function AutocompletePaciente({
  label,
  pholder,
  data,
  onSelected,
  onChange,
  resetValues,
  resetRuta,
}) {
  const [suggestions, setSugesstions] = useState([]);
  const [isHideSuggs, setIsHideSuggs] = useState(false);
  const [selectedVal, setSelectedVal] = useState(null);

  useEffect(() => {
    setSelectedVal(null);
    var getValue = document.getElementById("autocomplete-rutas");
    if (getValue.value != "") {
      getValue.value = "";
    }
  }, [resetValues, resetRuta]);
  const handler = (e) => {
    setSugesstions(
      data.filter((ruta) =>
        ruta.nombreRuta.toLowerCase().startsWith(e.target.value.toLowerCase())
      )
    );
  };

  const handleChange = (e) => {
    const input = e.target.value;
    setIsHideSuggs(input.length === 0 ? true : false);
    setSelectedVal(input);
    onChange(input);
  };

  const hideSuggs = (value, key) => {
    onSelected(key);
    setSelectedVal(value);
    setIsHideSuggs(true);
  };
  return (
    <div className="autocomplete-pacientes-container">
      <div className="form-autocomplete-pacientes">
        <input
          className="pacientes-input"
          placeholder=" "
          type="search"
          value={selectedVal}
          onChange={handleChange}
          onKeyUp={handler}
          name="autocomplete-rutas"
          id="autocomplete-rutas"
        />
        <label className="form-label-pacientes" htmlFor="autocomplete-rutas">
          {label}
        </label>
      </div>

      <div
        className="pacientes-suggestions"
        style={{ display: isHideSuggs ? "none" : "block" }}
      >
        {suggestions.map((item, idx) => (
          <div
            // key={"" + item.nombreRuta + idx}
            key={item.rutaId}
            onClick={() => {
              hideSuggs(item.nombreRuta, item.rutaId);
            }}
          >
            {item.nombreRuta}
          </div>
        ))}
      </div>
    </div>
  );
}
