import React from "react";

import "./Card.css";

export default function Card({ icon, text, onClick }) {
  return (
    <div className="card-container" onClick={onClick}>
      <div className="card-image-container">{icon}</div>
      <div className="card-text"> {text}</div>
    </div>
  );
}
