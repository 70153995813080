import axios from "axios";
// require('dotenv').config();

export default axios.create({
  baseURL: process.env.REACT_APP_BASE_URL || 'http://localhost:3000',
  headers: getHeaders(),
});
function getHeaders() {
  const user = JSON.parse(localStorage.getItem("user"));
  if (user && user.token) {
    return {
      "X-Requested-With": "XMLHttpRequest",
      Accept: "application/json",
      Authorization: "Bearer " + user.token,
    };
  } else {
    return {
      "X-Requested-With": "XMLHttpRequest",
      Accept: "application/json",
    };
  }
}
