import React, { useState } from "react";
import "./Modal.css";
import { RiCloseLine } from "react-icons/ri";
import Textfield from "../Textfield/Textfield";
import axios from "../../utils/axios";
import {
  errorHandler,
  openSuccessToast,
  openErrorToast,
} from "../../utils/errorToast";

export default function ModalEditUser({ setIsOpen, userToEdit, reloadUsers }) {
  const user = JSON.parse(localStorage.getItem("user"));
  const isAdmin = user?.roles.includes("ROLE_ADMIN");
  const [values, setValues] = useState({
    userId: userToEdit ? userToEdit.userId : "",
    nombreUsuario: userToEdit ? userToEdit.empleadoDto.nombreEmpleado : "",
    tipoDocumento: userToEdit ? userToEdit.empleadoDto.tipoDocumento : "",
    numeroDocumento: userToEdit ? userToEdit.cedula : "",
    rol: userToEdit ? userToEdit.originalRol : "",
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    setValues({
      ...values,
      [name]: value,
    });
  };
  const sendData = () => {
    if (
      values.userId &&
      values.numeroDocumento &&
      values.rol &&
      values.tipoDocumento &&
      values.nombreUsuario
    ) {
      let data = {
        userId: values.userId,
        cedula: values.numeroDocumento,
        rol: values.rol,
        empleadoDto: {
          empleadoId: userToEdit.empleadoDto.empleadoId,
          nombreEmpleado: values.nombreUsuario,
          tipoDocumento: values.tipoDocumento,
          numeroDocumento: values.numeroDocumento,
        },
      };
      axios
        .post("/editUser", data)
        .then((res) => {
          if (res.status >= 200 && res.status < 300) {
            openSuccessToast(
              "Se ha editado el usuario " +
                values.nombreUsuario +
                " correctamente"
            );
            setIsOpen(false);
            reloadUsers();
          }
        })
        .catch((error) => {
          errorHandler(error, "Error al editar el usuario");
        });
    } else {
      openErrorToast("Debe completar todos los campos");
    }
  };
  return (
    <>
      <div className="modal-dark-background" onClick={() => setIsOpen(false)} />
      <div className="modal-container">
        <div className="modal-zonas">
          <div className="modal-header">
            <h1 className="heading">Editar usuario</h1>
          </div>
          <button className="closeBtn" onClick={() => setIsOpen(false)}>
            <RiCloseLine style={{ marginBottom: "-3px" }} />
          </button>
          <div className="modalContent">
            <Textfield
              name="nombreUsuario"
              onChange={handleInputChange}
              value={values.nombreUsuario}
              text="Nombre completo"
              type="text"
            />
            <Textfield
              name="tipoDocumento"
              onChange={handleInputChange}
              value={values.tipoDocumento}
              text="Tipo de documento"
              type="text"
            />
            <Textfield
              name="numeroDocumento"
              onChange={handleInputChange}
              value={values.numeroDocumento}
              text="Número de documento"
              type="text"
            />
            <div className="form-group-rutas">
              <select
                className="form-input-select"
                placeholder=" "
                onChange={(e) => {
                  setValues({
                    ...values,
                    ["rol"]: e.target.value,
                  });
                }}
                value={values.rol}
              >
                <option key="administrador" value="ROLE_ADMIN">
                  ADMINISTRADOR
                </option>
                <option key="revisor" value="ROLE_REVISOR">
                  REVISOR
                </option>
                <option key="conductor" value="ROLE_CONDUCTOR">
                  CONDUCTOR
                </option>
                <option value="" selected disabled hidden>
                  Seleccione el rol
                </option>
              </select>
              <label htmlFor="rol" className="form-label-select">
                Rol
              </label>
            </div>

            <button type="submit" className="btn_submit" onClick={sendData}>
              Enviar datos
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
