import React, { useState, useEffect } from "react";
import "./Modal.css";
import { RiCloseLine } from "react-icons/ri";
import Textfield from "../Textfield/Textfield";
import { errorHandler, openSuccessToast } from "../../utils/errorToast";
import axios from "../../utils/axios";

export default function Modalpacientes({
  setIsOpen,
  reloadPacientes,
  paciente,
}) {
  const [values, setValues] = useState({
    id: paciente ? paciente.id : "",
    nombrePaciente: paciente ? paciente.nombrePaciente : "",
    tipoDocumento: paciente ? paciente.tipoDocumento : "",
    numeroDocumento: paciente ? paciente.numeroDocumento : "",
    eps: paciente ? paciente.eps : "",
    estadoPaciente: paciente ? paciente.estadoPaciente : "",
  });
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  const addPaciente = () => {
    let url = "";
    if (paciente) {
      url = "editPaciente";
    } else {
      url = "createPaciente";
    }
    axios
      .post(url, values)
      .then((res) => {
        if (res.status >= 200 && res.status < 300) {
          setIsOpen(false);
          openSuccessToast("Se ha guardado el paciente")
          reloadPacientes();
        }
      })
      .catch((error) => {
        let message = error.response?.data?.message
          ? error.response?.data?.message
          : "Error al crear paciente";
        errorHandler(error, message);
      });
  };
  return (
    <>
      <div className="modal-dark-background" onClick={() => setIsOpen(false)} />
      <div className="modal-container">
        <div className="modal-pacientes">
          <div className="modal-header">
            <h1 className="heading">
              {paciente ? "Editar paciente" : "Crear paciente"}
            </h1>
          </div>
          <button className="closeBtn" onClick={() => setIsOpen(false)}>
            <RiCloseLine style={{ marginBottom: "-3px" }} />
          </button>
          <div className="modalContent">
            <Textfield
              name="nombrePaciente"
              onChange={handleInputChange}
              value={values.nombrePaciente}
              text="Nombre paciente"
              type="text"
            />
            <Textfield
              name="tipoDocumento"
              onChange={handleInputChange}
              value={values.tipoDocumento}
              text="Tipo de documento"
              type="text"
            />
            <Textfield
              name="numeroDocumento"
              onChange={handleInputChange}
              value={values.numeroDocumento}
              text="Número de documento"
              type="text"
            />
            <Textfield
              name="eps"
              onChange={handleInputChange}
              value={values.eps}
              text="EPS"
              type="text"
            />
            <button type="submit" className="btn_submit" onClick={addPaciente}>
              {paciente ? "Guardar paciente" : "Agregar paciente"}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
