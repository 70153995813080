import React, { useEffect, useState } from "react";
import DatePicker from "react-multi-date-picker";
import axios from "../../utils/axios";
import { errorHandler, openErrorToast } from "../../utils/errorToast";
import AutocompletePaciente from "../AutocompletePaciente/AutocompletePaciente";
import AutocompleteRutas from "../AutocompletePaciente/AutocompleteRutas";
import Loading from "../Loading/Loading";
import ModalPeajesImpulsos from "../Modal/ModalPeajesImpulsos";
import ModalPacientes from "../Modal/ModalPacientes";
import Textfield from "../Textfield/Textfield";
import "./CuentaCobroFields.css";
import ModalPacienteDialisis from "../Modal/ModalPacienteDialisis";

export default function CuentaCobroFields({
  handleAddValues,
  setPeajes,
  setImpulsos,
  peajeCuenta,
  impulsosCuenta,
}) {
  const months = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];
  const user = JSON.parse(localStorage.getItem("user"));
  const [rutas, setRutas] = useState([]);
  const [pacientes, setPacientes] = useState([]);
  const [resetValues, setResetValues] = useState(false);
  const [resetRuta, setResetRuta] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalInfo, setModalInfo] = useState({
    text: "",
    action: "",
    value: 0,
  });
  const [values, setValues] = useState({
    fecha: "",
    nombreUsuario: "",
    origen: "",
    destino: "",
    tipoRecorrido: "",
    valor: "",
    paciente: {
      numeroDocumento: "",
      nombrePaciente: "",
      eps: "",
    },
    ruta: {},
    esAdicional: false,
    valorAdicional: 0,
    precioActual: 0,
  });
  const [dates, setDates] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isModalPacienteOpen, setIsModalPacienteOpen] = useState(false);
  const hasPeajesOrImpulsos =
    user?.empleado?.tienePeajes || user?.empleado?.tieneImpulsos;

  useEffect(() => {
    getRutasByEmpleados();
    getPacientesHabilitados();
  }, [setPacientes]);
  useEffect(() => {
    getRutasByEmpleados();
  }, [values.tipoRecorrido]);
  useEffect(() => {
    if (values.tipoRegistro === "SOBRANTE") {
      setValues({
        ...values,
        ["responsablePlanilla"]: user.empleado.nombreEmpleado,
      });
    } else if (values.tipoRegistro === "INTERNO") {
      setValues({
        ...values,
        ["responsablePlanilla"]: user.empleado.nombreEmpleado,
        ["conductorInicio"]: user.empleado.nombreEmpleado,
        ["conductorFinal"]: user.empleado.nombreEmpleado,
      });
    } else {
      setValues({
        ...values,
        ["responsablePlanilla"]: "",
        ["conductorInicio"]: "",
        ["conductorFinal"]: "",
      });
    }
  }, [values.tipoRegistro]);
  const getRutasByEmpleados = () => {
    setIsLoading(true);
    axios
      .get("getAllRutasByEmpleadoZonas", {
        params: {
          tipoRuta: values.tipoRecorrido,
        },
      })
      .then((res) => {
        if (res.status >= 200 && res.status < 300) {
          setRutas(res.data);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        errorHandler(error);
      });
  };
  const getPacientesHabilitados = () => {
    setIsLoading(true);
    axios
      .get("getPacientesHabilitados")
      .then((res) => {
        if (res.status >= 200 && res.status < 300) {
          setIsLoading(false);
          setPacientes(res.data);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        errorHandler(error);
      });
  };
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setValues({
      ...values,
      [name]: value.toUpperCase(),
    });
  };

  const addValues = () => {
    if (
      dates.length > 0 &&
      values.ruta?.rutaId &&
      values.paciente.numeroDocumento
    ) {
      if (
        values.tipoRecorrido === "REDONDO" ||
        (values.tipoRecorrido === "SENCILLO" && values.tipoRegistro)
      ) {
        if (
          values.ruta.eps === null ||
          values.ruta.eps === "" ||
          values.paciente.eps === values.ruta.eps
        ) {
          handleAddValues(values, dates);
          setValues({
            fecha: "",
            nombreUsuario: "",
            origen: "",
            destino: "",
            tipoRecorrido: "",
            valor: "",
            paciente: {
              numeroDocumento: "",
              nombrePaciente: "",
              eps: "",
            },
            ruta: {},
            esAdicional: false,
            valorAdicional: 0,
            conductorInicio: "",
            conductorFinal: "",
            responsablePlanilla: "",
            precioActual: 0,
          });
          setDates([]);
          setResetValues(!resetValues);
        } else {
          openErrorToast("Esta ruta no se puede usar para la eps seleccionada");
        }
      } else {
        openErrorToast("Debe seleccionar un tipo de registro");
      }
    } else {
      openErrorToast("Debe completar todos los campos");
    }
  };
  const getSelectedVal = (value) => {
    const found = pacientes.find((p) => p.numeroDocumento === value);

    setValues({
      ...values,
      ["paciente"]: found,
    });
    if (found.askForDialisis === null || found.askForDialisis) {
      setIsModalPacienteOpen(true);
    }
  };

  const getChanges = () => {
    setValues({
      ...values,
      ["paciente"]: {
        numeroDocumento: "",
        nombrePaciente: "",
        eps: "",
      },
    });
  };
  const getSelectedRuta = (value) => {
    const found = rutas.find((p) => p.rutaId === value);
    setValues({
      ...values,
      ["ruta"]: found,
      ["precioActual"]: found.precio,
    });
  };

  const getChangesRuta = () => {
    setValues({
      ...values,
      ["ruta"]: {},
    });
  };

  const handleOpenPeaje = () => {
    setModalInfo({
      text: "Agregar peaje",
      action: setPeajes,
      value: peajeCuenta,
    });
    setIsModalOpen(true);
  };

  const handleOpenImpulso = () => {
    setModalInfo({
      text: "Agregar impulso",
      action: setImpulsos,
      value: impulsosCuenta,
    });
    setIsModalOpen(true);
  };

  const handleOnClickDialisis = (action) => {
    let data = values.paciente;
    data = {
      ...data,
      ["isDialisis"]: action,
    };
    setIsLoading(true);
    axios
      .post("changePacienteDialisis", data)
      .then((res) => {
        if (res.status >= 200 && res.status < 300) {
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        errorHandler(error);
      });
  };

  return (
    <div className="main-container-form-cuenta">
      <div
        className={
          hasPeajesOrImpulsos
            ? "container-form-cuenta-withactions"
            : "container-form-cuenta"
        }
      >
        <div className="container-fields-cuenta">
          <AutocompletePaciente
            label="Cédula paciente"
            pholder="Cédula paciente..."
            data={pacientes}
            onSelected={getSelectedVal}
            onChange={getChanges}
            resetValues={resetValues}
          />
          <Textfield
            name="nombrePaciente"
            onChange={handleInputChange}
            value={values.paciente.nombrePaciente}
            text="Nombre del paciente"
            type="text"
            disabled={true}
          />
          <Textfield
            name="epsPaciente"
            onChange={handleInputChange}
            value={values.paciente.eps}
            text="EPS"
            type="text"
            disabled={true}
          />
          <div className="form-group-select-cc">
            <select
              className="form-input-select"
              placeholder=" "
              onChange={(e) => {
                setValues({
                  ...values,
                  ["tipoRecorrido"]: e.target.value,
                  ["ruta"]: {},
                });
                setResetRuta(!resetRuta);
              }}
              value={values.tipoRecorrido}
            >
              <option key="sencillo" value="SENCILLO">
                SENCILLO
              </option>
              <option key="redondo" value="REDONDO">
                REDONDO
              </option>
              <option value="" selected disabled hidden>
                Seleccione el tipo de recorrido
              </option>
            </select>
            <label htmlFor="tipoRecorrido" className="form-label-select">
              Tipo de recorrido
            </label>
          </div>
          <DatePicker
            inputClass="form_input_date"
            multiple
            value={dates}
            onChange={setDates}
            placeholder="Fecha"
            required
            format={"DD/MM/YYYY"}
            months={months}
          />
          <AutocompleteRutas
            label="Ruta"
            data={rutas}
            onSelected={getSelectedRuta}
            onChange={getChangesRuta}
            resetValues={resetValues}
            resetRuta={resetRuta}
          />
          {values.tipoRecorrido === "SENCILLO" && (
            <div className="form-registros-adicionales">
              <div className="form-group-select-cc">
                <select
                  className="form-input-select"
                  placeholder=" "
                  onChange={(e) => {
                    setValues({
                      ...values,
                      ["tipoRegistro"]: e.target.value,
                    });
                  }}
                  value={values.tipoRegistro}
                >
                  <option key="faltante" value="FALTANTE">
                    FALTANTE
                  </option>
                  <option key="sobrante" value="SOBRANTE">
                    SOBRANTE
                  </option>
                  <option key="sencillo interno" value="INTERNO">
                    INTERNO
                  </option>
                  <option value="" selected disabled hidden>
                    Seleccione el tipo de registro
                  </option>
                </select>
                <label htmlFor="tipoRegistro" className="form-label-select">
                  Tipo de registro
                </label>
              </div>
              <Textfield
                name="conductorInicio"
                onChange={handleInputChange}
                value={values.conductorInicio}
                text="Conductor que lo inició"
                type="text"
              />
              <Textfield
                name="conductorFinal"
                onChange={handleInputChange}
                value={values.conductorFinal}
                text="Conductor que lo finalizó"
                type="text"
              />
              <Textfield
                name="responsablePlanilla"
                onChange={handleInputChange}
                value={values.responsablePlanilla}
                text="Responsable de entregar la planilla"
                type="text"
              />
            </div>
          )}
        </div>
        {hasPeajesOrImpulsos && (
          <div className="container-buttons-cuenta">
            {user?.empleado?.tienePeajes && (
              <div className="container-btn-peajes">
                <button
                  type="submit"
                  className="btn_submit"
                  onClick={handleOpenPeaje}
                >
                  Agregar peajes
                </button>
              </div>
            )}
            {user?.empleado?.tieneImpulsos && (
              <div className="container-btn-peajes">
                <button
                  type="submit"
                  className="btn_submit"
                  onClick={handleOpenImpulso}
                >
                  Agregar impulsos
                </button>
              </div>
            )}
          </div>
        )}
      </div>
      <div className="container-submit-buttons-cuenta">
        <button type="submit" className="btn_submit" onClick={addValues}>
          Agregar
        </button>
      </div>

      {isModalOpen && (
        <ModalPeajesImpulsos setIsOpen={setIsModalOpen} info={modalInfo} />
      )}
      {isLoading && <Loading />}

      {isModalPacienteOpen && (
        <ModalPacienteDialisis
          setIsOpen={setIsModalPacienteOpen}
          reloadPacientes={getPacientesHabilitados}
          handleOnClickDialisis={handleOnClickDialisis}
          numeroDocumento={values.paciente.numeroDocumento}
        />
      )}
    </div>
  );
}
