import React, { useState, useEffect } from "react";
import "./Modal.css";
import { RiCloseLine } from "react-icons/ri";
import Textfield from "../Textfield/Textfield";
import { errorHandler, openSuccessToast } from "../../utils/errorToast";
import axios from "../../utils/axios";
import FormConductor from "../FormConductor/FormConductor";

export default function Modalpacientes({
  setIsOpen,
  reloadConductores,
  conductor,
}) {
  return (
    <>
      <div className="modal-dark-background" onClick={() => setIsOpen(false)} />
      <div className="modal-container">
        <div className="modal-conductores">
          <div className="modal-header">
            <h1 className="heading">
              {conductor ? "Editar titular" : "Crear titular"}
            </h1>
          </div>
          <button className="closeBtn" onClick={() => setIsOpen(false)}>
            <RiCloseLine style={{ marginBottom: "-3px" }} />
          </button>
          <FormConductor
            conductor={conductor}
            setIsOpen={setIsOpen}
            reloadConductores={reloadConductores}
          />
        </div>
      </div>
    </>
  );
}
