import React from "react";
import "./Textfield.css";

export default function Textfield({
  name,
  onChange,
  value,
  text,
  type,
  disabled,
  className,
}) {
  return (
    <div className="form-group">
      <input
        type={type}
        className={className ? className : "form-input"}
        name={name}
        placeholder=" "
        onChange={onChange}
        value={value}
        id={name}
        disabled={disabled}
      />
      <label htmlFor={name} className="form-label">
        {text}
      </label>
    </div>
  );
}
