import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import RequireAuth from "./RequireAuth";
import FormRegistroPage from "./pages/FormRegistroPage/FormRegistroPage";
import EditConductorPage from "./pages/EditConductorPage/EditConductorPage";
import CuentaCobroPage from "./pages/CuentaCobroPage/CuentaCobroPage";
import HomePage from "./pages/Home/HomePage";
import LoginPage from "./pages/LoginPage/LoginPage";
import ViewCuentasCobroPage from "./pages/ViewCuentasCobroPage/ViewCuentasCobroPage";
import ConductoresPage from "./pages/ConductoresPage/ConductoresPage";
import RutasPage from "./pages/RutasPage/RutasPage";
import PacientesPage from "./pages/PacientesPage/PacientesPage";
import AuthVerify from "./AuthVerify";
import ViewUsuariosPage from "./pages/ViewUsuariosPage/ViewUsuariosPage";

const logout = () => {
  localStorage.removeItem("user");
};
// const clearCacheData = () => {
//   caches.keys().then((names) => {
//     names.forEach((name) => {
//       caches.delete(name);
//     });
//   });
// };
export default (
  <div>
    {/* {clearCacheData()} */}
    <Routes>
      <Route path="/login" element={<LoginPage />} />
      <Route path="/registro" element={<FormRegistroPage />} />
      <Route path="/" element={<Navigate to="/home" />} />
      <Route
        path="/home"
        element={
          <RequireAuth>
            <HomePage />
          </RequireAuth>
        }
      />
      <Route
        path="/cuenta"
        element={
          <RequireAuth>
            <CuentaCobroPage />
          </RequireAuth>
        }
      />
      <Route
        path="/vercuentas"
        element={
          <RequireAuth>
            <ViewCuentasCobroPage />
          </RequireAuth>
        }
      />
      <Route
        path="/conductores"
        element={
          <RequireAuth>
            <ConductoresPage />
          </RequireAuth>
        }
      />
      <Route
        path="/rutas"
        element={
          <RequireAuth>
            <RutasPage />
          </RequireAuth>
        }
      />
      <Route
        path="/pacientes"
        element={
          <RequireAuth>
            <PacientesPage />
          </RequireAuth>
        }
      />
      <Route
        path="/verusuarios"
        element={
          <RequireAuth>
            <ViewUsuariosPage />
          </RequireAuth>
        }
      />
      <Route
        path="/editarconductor"
        element={
          <RequireAuth>
            <EditConductorPage />
          </RequireAuth>
        }
      />
    </Routes>
    <AuthVerify logOut={logout} />
  </div>
);
