import React, { useState } from "react";
import "./Modal.css";
import { RiCloseLine } from "react-icons/ri";

export default function ModalPacienteDialisis({
  setIsOpen,
  handleOnClickDialisis,
  numeroDocumento,
}) {
  const onClickAction = (action) => {
    setIsOpen(false);
    handleOnClickDialisis(action);
  };
  return (
    <>
      <div className="modal-dark-background" onClick={() => setIsOpen(false)} />
      <div className="modal-container">
        <div className="modal-confirmation">
          <button className="closeBtn" onClick={() => setIsOpen(false)}>
            <RiCloseLine style={{ marginBottom: "-3px" }} />
          </button>
          <div className="modalContent-confirmation">
            <h1 className="heading">
              ¿El paciente {numeroDocumento} es de diálisis?
            </h1>
            <div className="container-buttons-modal-dialisis">
              <button
                type="submit"
                className="btn-yes"
                onClick={() => onClickAction("Si")}
              >
                Si
              </button>
              <button
                type="submit"
                className="btn-no"
                onClick={() => onClickAction("No")}
              >
                No
              </button>
              <button
                type="submit"
                className="btn-cancel"
                onClick={() => setIsOpen(false)}
              >
                Cancelar
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
